const state = {
  Token: window.localStorage.getItem('Token') || '',
  UserName: window.localStorage.getItem('UserName') || ''
};

const mutations = {
  TOKEN_STATUS(state, payload) {
    state.Token = payload.Token;
    state.UserName = payload.UserName;
  }
};

const actions = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

