import Vue from 'vue'
import VueRouter from "vue-router";
import login from "@/router/login";
import admin from "@/router/admin";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: "history",
    routes:[
        {path:'/', redirect:  'login'},
        {path:'/admin', redirect:  'dynamic'},
        admin,
        login,
        {
            path:'/register',
            name: 'register',
            component : ()=> import('@/components/admin/register')
        },
        {
            path:'/forget',
            name: 'forget',
            component : ()=> import('@/components/admin/forget')
        }
    ]
})
router.beforeEach((to, from, next)=>{
    let token = localStorage.getItem('Token');
    if (to.path === '/login' || to.path === '/forget' || to.path === '/register') {
        if (token === null || token === '') {
            next();
        } else {
            next('/admin')
        }
    } else {
        if (token === null || token === '') {
            next('/login');
        } else {
            next();
        }
    }
})
export default router;
