import Vue from 'vue'
import App from './App.vue'
import $ from 'jquery'
import router from "./router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from "axios";
import Vuex from 'vuex'
import store from './store'

import VueQuillEditor from "vue-quill-editor/dist/ssr";
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

Vue.use(VueQuillEditor)
Vue.use(Vuex)

axios.interceptors.request.use(
    confige => {
      const token = localStorage.getItem('Token')
      if (token) {
        confige.headers.token = token
      }
      return confige;
    },
    error => {
      return Promise.reject(error);
    });

axios.interceptors.response.use(
    response => {
      if (!(response.data.status) && response.data.msg.indexOf("登陆已超时") >=0 ) {
        console.log(response.data.msg.indexOf("登陆已超时") >=0)
        window.localStorage.setItem('Token','')
      }
      return response;
    },
    error => {
      return Promise.reject(error);
    }
)


Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.prototype.$ = $
Vue.use(ElementUI)


new Vue({
  el: '#app',
  router,
  store: store,
  render: h => h(App),
}).$mount('#app')
