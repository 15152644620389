export default {
    path: '/admin',
    name: 'admin',
    component: () => import('@/components/admin/admin'),
    meta: {
        requireAuth: true
    },
    children: [
        {
            path: '/oil',
            component: () => import('@/components/Lmain/oil')
        },
        {
            path: '/dynamic',
            component: () => import('@/components/Lmain/dynamic')
        },
        {
            path: '/insertOil',
            component: () => import('@/components/Lmain/insertOil')
        },
        {
            path: '/usedCar',
            component: () => import('@/components/Lmain/usedCar')
        },
        {
            path: '/insertUsedCar',
            component: () => import('@/components/Lmain/insertUsedCar')
        },
        {
            path: '/system',
            component: () => import('@/components/Lmain/system')
        },
        {
            path: '/car',
            component: () => import('@/components/Pconfiguration/car')
        },
        {
            path: '/dep',
            component: () => import('@/components/Pconfiguration/department')
        },
        {
            path: '/card',
            component: () => import('@/components/Pconfiguration/card')
        },
        {
            path: '/emp',
            component: () => import('@/components/Pconfiguration/employee')
        },
        {
            path: '/user',
            component: () => import('@/components/Sconfiguration/user')
        },
        {
            path: '/relo',
            component: () => import('@/components/Sconfiguration/relo')
        },
        {
            path: '/permission',
            component: () => import('@/components/Sconfiguration/permission')
        },
        {
            path: '/visitor',
            component: () => import('@/components/Sconfiguration/visitor')
        },
        {
            path: '/dynamicControl',
            component: () => import('@/components/Lmain/dynamixControl')
        }
        ,
        {
            path: '/message',
            component: () => import('@/components/WebSite/message')
        }
        ,
        {
            path: '/release',
            component: () => import('@/components/WebSite/release')
        }
        ,
        {
            path: '/sitconfig',
            component: () => import('@/components/WebSite/sitconfig')
        }
        ,
        {
            path: '/reader',
            component: () => import('@/components/WebSite/reader')
        }
        ,
        {
            path: '/editer',
            component: () => import('@/components/WebSite/editer')
        },
        {
            path: '/people',
            component: () => import('@/components/WebSite/people')
        },
        {
            path: '/notice',
            component: () => import('@/components/WebSite/notice')
        },
        {
            path: '/peoReader',
            component: () => import('@/components/WebSite/peoReader')
        },
        {
            path: '/peoEditer',
            component: () => import('@/components/WebSite/peoEditer')
        },
        {
            path: '/tag',
            component: () => import('@/components/WebSite/tag')
        },
        {
            path: '/editPassWd',
            component: () => import('@/components/admin/editPassWd')
        },
        {
            path: '/userInfo',
            component: () => import('@/components/Sconfiguration/userInfo')
        },
        {
            path: '/ipData',
            component: () => import('@/components/Sconfiguration/ipData')
        },
        {
            path: '/logonLogs',
            component: () => import('@/components/Sconfiguration/logonLogs')
        },
        {
            path: '/Customer',
            component: () => import('@/components/Crm/Customer')
        },
        {
            path: '/stock',
            component: () => import('@/components/Erp/Stock')
        }

    ]
}
